"use client";
import React from "react";
import Image from "next/image";
import LogoPurplePink from "@/assets/Logos/LogoPurplePink.png";
import { useSession } from "next-auth/react";
import { Button } from "../Button/Button";
import { useRouter } from "next/navigation";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

export const LandingSection = ({}) => {
  const { data: session, status } = useSession();
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const router = useRouter();

  return (
    <section className=" w-screen h-fit">
      {/* <div className="text-6xl tracking-wider font-bold">LOOD</div> */}

      <div className="grid grid-rows-fr border-black shadow-innerShadowY bg-bg-image animate-bg-pan h-full bg-cover px-8">
        <div className="flex flex-col lg:pt-32 items-center w-">
          <Image src={LogoPurplePink} height={400} width={400} alt="logo"></Image>

          <h1 className="lg:text-5xl italic md:text-4xl font-bold text-darkPurple mt-16 mb-8 text-center">
            Where You&apos;re More Than Friends
          </h1>
          <div className="flex space-x-3 justify-center items-center mb-5">
            <Button
              label="Become a Creator"
              type="tertiary"
              onClick={() => {
                router.push("/onboard/verification");
              }}
            ></Button>

            {!isConnected ? (
              <Button label="Login" type="tertiary" onClick={openConnectModal}></Button>
            ) : (
              <Button
                label="Let's Chat"
                type="tertiary"
                onClick={() => {
                  router.push("/chat");
                }}
              ></Button>
            )}
            {/* status === "authenticated" ? <ChatsIcon className="my-10 h-16 hover:cursor-pointer hover:scale-105"/>: <Button  label='Log In' type='primary' onClick={() => {}} ></Button>*/}
          </div>
          {/* <div className="text-black grid grid-cols-2 w-screen h-screen">
          </div> */}
          {/* <div className="flex flex-col lg:py-32 items-center">
            <p className="text-xl text-black font-semibold tracking-wide  w-4/6">
              Meet Lood, the game-changer for influencers worldwide. Its not just another chatbot - picture a
              personalized AI companion trained on influencer personas, offering genuine connections and consistent
              companionship. In a world where online presence is everything, Lood redefines the influencer experience to
              another level. Break free from the limitations of 1:1 interaction - with 100% social uptime, Lood is
              always ready to connect authentically with fans
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
};
