"use client";

import PageWrapper from "@/components/PageWrapper";
import { LandingSection } from "@/components/Section/Landing";
import { tw } from "@/utils/helpers";
import { useState, useEffect } from "react";
import { IArtist } from "@/models/artist";
import { FeatureTile } from "@/components/Tile/FeatureTile";

export const revalidate = 60; // Revalidate every 60 seconds

export default function Token() {
  const [artists, setArtists] = useState<IArtist[]>([]);

  useEffect(() => {
    fetch(`/api/profile/artists/`, { next: { revalidate: 60 } })
      .then((res) => res.json())
      .then((data) => {
        const slideData = data
          .filter((a: IArtist) => a.featured === true)
          .map((a: IArtist, index: number) => ({
            index: index,
            username: a.username,
            button: "Talk Now",
            imgSrc: a.imgSrc,
          }));
        setArtists(slideData);
      });
  }, []);

  return (
    <PageWrapper>
      <LandingSection />
      <div className="mt-20 justify-center items-center">
        <div className="flex flex-row flex-wrap gap-12 w-full">
          {artists.map((artist) => {
            return (
              <FeatureTile
                key={artist.username}
                src={artist.imgSrc}
                name={artist.username}
                path={`chat/${artist.username}`}
                isFavorite={false}
                status={artist.status}
              />
            );
          })}
        </div>
      </div>
      <div
        className={tw(
          "bg-loodpink rounded-none md:rounded-lg drop-shadow-lg max-w-5xl my-28 p-16 items-center flex flex-col",
          "border-4 bg-darkGreen shadow-loodshadow"
        )}
      >
        <div className="text-4xl font-bold mb-12 text-center">What Makes our LLM Models Different?</div>
        <div className="text-white text-lg py-16">
          Lood provides an experience everyone is looking for - an attentive, palliative, and understanding intelligence
          based on people you identify with. From therapy to eroticism, your new AI partner is there for you and costs
          1/20th of the cost of a real partnership. You&apos;ll get all the validating feelings of a real relationship
          without the risk of a broken heart or infidelity. Your lood.ai model will always be there for you; in whatever
          capacity you need them.
        </div>
      </div>
    </PageWrapper>
  );
}
