import { tw } from "@/utils/helpers";

interface ButtonProps {
  label: string;
  type: "primary" | "secondary" | "tertiary" | "lood" | "submit";
  onClick: (() => void) | undefined;
  disabled?: boolean;
}

const buttonStyles = {
  primary: tw("bg-loodpink text-white border-2 border-loodblue"),
  secondary: tw("text-white text-sm border-2 border-black bg-darkPurple py-2 px-2"),
  tertiary: tw(
    "bg-white text-black font-normal text-lg border-2 border-darkPurple shadow-md shadow-darkPink w-fit py-4 px-4 "
  ),
  lood: tw("bg-white text-black font-normal text-lg border-2 darkPurple shadow-md shadow-darkPink w-fit py-4 px-4 "),
  submit: tw("text-white border-2 border-black bg-lightPurple font-normal py-4 px-8"),
};

export function Button({ label, onClick, type, disabled }: ButtonProps) {
  return (
    <button
      className={tw(
        "font-bold tracking-wider rounded-lg cursor-pointer",
        "transition-all drop-shadow-md hover:scale-105",
        buttonStyles[type]
      )}
      onClick={onClick}
      disabled={disabled}
      type={type === "submit" ? "submit" : "button"}
    >
      {label}
    </button>
  );
}
